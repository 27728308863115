<template>
  <div class="t3-ce-html">
    <template v-if="isContactFormHere">
      <DelayHydration>
        <ContactForm />
      </DelayHydration>
    </template>

    <template v-if="isTrafiitJobListHere">
      <DelayHydration>
        <TraffitJobOffers />
      </DelayHydration>
    </template>

    <template v-else>
      <DelayHydration>
        <T3HtmlParser :content="bodytext" />
      </DelayHydration>
    </template>
  </div>
</template>

<script setup lang="ts">
import type { T3Appearance } from '@t3headless/nuxt-typo3'
import { computed, defineAsyncComponent } from 'vue'

interface T3CeHtml {
  uid: number
  index: number
  appearance: T3Appearance
  bodytext: string
}

const props = withDefaults(defineProps<T3CeHtml>(), {
  bodytext: ''
})

const ContactForm = defineAsyncComponent(
  () => import('~shared/components/Base/FormComponents/ContactForm')
)
const isContactFormHere = computed(() =>
  props.bodytext.includes('id="contact-form"')
)

const TraffitJobOffers = defineAsyncComponent(
  () => import('~shared/components/Base/TraffitJobOffers')
)
const isTrafiitJobListHere = computed(() =>
  props.bodytext.includes('id="traffit-offers"')
)
</script>
